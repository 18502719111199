






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Mixins, Emit, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import { Billing, DEFAULT_BILLING } from "@/model/agreement/billing";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";
import {
  ChikenLinkInfo,
  DEFAULT_CHIKEN_LINK_INFO,
} from "@/model/agreement/chikenLink";
import BillingFileUploader from "@/components/contractreception/officeinfoeditor/BillingFileUploader.vue";
import {
  PREFECTURES,
  USE_RECEIPT_TYPE,
  STOP_REASON,
  AGREEMENT_PLAN_TYPE,
  NEED_RECEIPT_SYSTEM_MAIL,
  BANK_SELECTION,
  BANK_TYPE,
  BRANCH_TYPE,
  DEPOSIT_TYPE,
} from "@/consts/const";
import RulesMixin from "#/mixins/rulesMixin";
import KintaiLinkInfoViewer from "@/components/contract/KintaiLinkInfoViewer.vue";
import ChikenLinkInfoViewer from "@/components/contract/ChikenLinkInfoViewer.vue";
import OfficeInfoAiReportEditor from "@/components/contractreception/OfficeInfoAiReportEditor.vue";

@Component({
  components: {
    TextDayChangePicker,
    BillingFileUploader,
    KintaiLinkInfoViewer,
    ChikenLinkInfoViewer,
    OfficeInfoAiReportEditor,
  },
})
export default class OfficeInfoEditor extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin
) {
  @Prop({ default: () => DEFAULT_OFFICE }) office!: Office;
  @Prop({ default: () => DEFAULT_BILLING }) billing_info!: Billing;
  @Prop({ default: "" }) co_code!: string;
  /** 営業に関わる項目をdisabledにするかどうかフラグ(契約時と編集時で分けたいため) */
  @Prop({ default: false }) disableSalesItem!: boolean;
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;
  @Prop({ default: () => DEFAULT_CHIKEN_LINK_INFO })
  chikenLinkInfo!: ChikenLinkInfo;
  /** 事業所名の編集ができるかどうか */
  @Prop({ default: false }) disableOfficeName!: boolean;

  /** 停止設定制御用 */
  private disableStopSettingItems = true;
  private disablePauseEndItem = true;

  /** 契約開始日の変更時、終了日を自動決定 */
  private onCHangeStartDate() {
    // 契約プランが選択されていない場合や既に終了日が入っている場合、自動決定スキップ
    if (
      this.billing_info.agreement_plan_type == 0 ||
      this.billing_info.end_date != ""
    ) {
      return;
    }

    // 契約開始日を空にした場合は、終了日も空
    if (this.billing_info.start_date == "") {
      this.billing_info.end_date = "";
      return;
    }

    // 契約プランに応じた年数を足し、その1日前を終了日とする
    const startDt = this.newDate(this.billing_info.start_date);
    let addYear = 0;
    switch (this.billing_info.agreement_plan_type) {
      case 1:
      case 2:
      case 3:
      case 8:
      case 9:
      case 10:
        addYear = 2;
        break;
      case 4:
      case 7:
      case 13:
        addYear = 5;
        break;
      case 5:
      case 6:
      case 11:
        addYear = 3;
        break;
      case 12:
        addYear = 4;
        break;
      case 14:
        addYear = 7;
        break;
      default:
        return;
    }

    startDt.setFullYear(startDt.getFullYear() + addYear);
    startDt.setDate(startDt.getDate() - 1);

    this.billing_info.end_date = this.dateToStr(startDt, "yyyy-MM-dd");
  }

  /** システム停止設定の項目が変更された場合 */
  @Watch("billing_info.stop_reason", { immediate: true })
  onChangeStopReason() {
    // 未選択の場合は内容を全て空にリセットして全体非表示
    if (this.billing_info.stop_reason == 0) {
      this.billing_info.stop_date = "";
      this.billing_info.pause_end = "";
      this.billing_info.stop_memo = "";
      this.disableStopSettingItems = true;
      return;
    }

    // 休止の場合は全体表示
    if (this.billing_info.stop_reason == 3) {
      this.disableStopSettingItems = false;
      this.disablePauseEndItem = false;
      return;
    }

    // それ以外の場合は停止解除日を空&非表示
    this.disableStopSettingItems = false;
    this.disablePauseEndItem = true;
    this.billing_info.pause_end = "";

    return;
  }

  @Emit()
  private update() {
    return;
  }

  /** 定数関連 */
  private PREFECTURES = PREFECTURES;
  private USE_RECEIPT_TYPE = USE_RECEIPT_TYPE;
  private STOP_REASON = STOP_REASON;
  private AGREEMENT_PLAN_TYPE = AGREEMENT_PLAN_TYPE;
  private NEED_RECEIPT_SYSTEM_MAIL = NEED_RECEIPT_SYSTEM_MAIL;
  private BANK_SELECTION = BANK_SELECTION;
  private BANK_TYPE = BANK_TYPE;
  private BRANCH_TYPE = BRANCH_TYPE;
  private DEPOSIT_TYPE = DEPOSIT_TYPE;

  /** 請求先の金融機関としてゆうちょ銀行を選択しているかどうかフラグ */
  private get isYuchoSelected(): boolean {
    return this.billing_info.bank_selection == 1;
  }

  private clickKintaiLinkButton() {
    if (this.billing_info.is_attendance_use == 0) {
      // 未連携の場合はKINTAIへつなぐ
      this.connetcToIbowKintai();
    } else {
      // 連携済みは状態変更のみ
      this.updateKintaiLinkStatus();
    }
  }

  // 勤怠データ連携
  private connetcToIbowKintai() {
    this.postJsonCheck(
      window.base_url + "/api/admin/kintai/datalink",
      {
        agreement_id: Number(this.$route.params.id),
        billing: this.billing_info,
      },
      () => {
        this.update();
      }
    );

    // iBowKintaiと連携する
    // 連携成功時、IsAttendanceUse(使用フラグ)を立てて連携情報を表示する
    return;
  }

  // 連携状態更新
  private updateKintaiLinkStatus() {
    this.postJsonCheck(
      window.base_url + "/api/admin/kintai/updatelink",
      {
        agreement_id: Number(this.$route.params.id),
        billing: this.billing_info,
      },
      () => {
        this.update();
      }
    );
  }

  //------------------------------------
  // iBow治験連携
  //------------------------------------
  private clickChikenLinkButton() {
    if (this.billing_info.is_chiken_use == 0) {
      // 未連携の場合はiBow治験へつなぐ
      this.connetcToIbowChiken();
    } else {
      // 連携済みは状態変更のみ
      this.updateChikenLinkStatus();
    }
  }

  // 治験データ連携
  private connetcToIbowChiken() {
    this.postJsonCheck(
      window.base_url + "/api/admin/chiken/datalink",
      {
        agreement_id: Number(this.$route.params.id),
        billing: this.billing_info,
      },
      () => {
        this.update();
      }
    );
    return;
  }

  // 連携状態更新
  private updateChikenLinkStatus() {
    this.postJsonCheck(
      window.base_url + "/api/admin/chiken/updatelink",
      {
        agreement_id: Number(this.$route.params.id),
        billing: this.billing_info,
      },
      () => {
        this.update();
      }
    );
  }

  /** 試用期間に関するバリデーションチェック */
  private get CheckTrialPeriod(): boolean | string {
    // 開始と終了の順序チェック
    if (
      this.dateAfter(
        this.billing_info.trial_start_date,
        this.billing_info.trial_end_date,
        true,
        ""
      ) !== true
    ) {
      return "試用期間終了日は試用期間開始日以降を設定して下さい。";
    }
    return true;
  }

  /** システム停止日に関するバリデーションチェック */
  private get CheckStopDate(): boolean | string {
    // 終了、中途解約を選択しているのに空の場合はNG
    if (
      (this.billing_info.stop_reason == 1 ||
        this.billing_info.stop_reason == 2) &&
      this.billing_info.stop_date == ""
    ) {
      return "必須項目です。";
    }
    return true;
  }

  /** システム停止解除日に関するバリデーションチェック */
  private get CheckPauseEndDate(): boolean | string {
    // 休止を選択しているのに空の場合はNG
    if (
      this.billing_info.stop_reason == 3 &&
      this.billing_info.pause_end == ""
    ) {
      return "必須項目です。";
    }
    return true;
  }

  /** レセプト機能ON時の日付必須チェック */
  private checkReceiptDateRequired(date: string): boolean | string {
    if (this.billing_info.ibow_receipt_div && !date) {
      return "必須項目です。";
    }
    return true;
  }

  /** レセプト機能ON時の日付必須チェック */
  private checkOcrDateRequired(date: string): boolean | string {
    if (this.billing_info.is_ocr_enabled && !date) {
      return "必須項目です。";
    }
    return true;
  }

  /** OJT研修機能ON時の日付必須チェック */
  private checkOjtDateRequired(date: string): boolean | string {
    if (this.billing_info.use_ojt && !date) {
      return "必須項目です。";
    }
    return true;
  }

  /** 契約プラン名バリデーション */
  private get CheckPlanName(): boolean | string {
    if (this.IsOtherPlan && this.billing_info.agreement_plan_name == "") {
      return "必須項目です。";
    }

    return true;
  }

  /** 契約プランがその他か */
  private get IsOtherPlan(): boolean {
    if (this.billing_info.agreement_plan_type == 99) {
      return true;
    }
    return false;
  }

  /** 現在日がアカウント通知日〜システム停止日の間はdisableにする */
  private get DisableOfficeName(): boolean {
    // アカウント通知日が未設定の場合はdisableにしない
    if (!this.billing_info.account_notice_date) {
      return false;
    }

    // アカウント通知日<=現在日<=システム停止日の間はdisableにする
    const today = new Date();
    const account_notice_date = this.newDate(
      this.billing_info.account_notice_date
    );
    if (
      account_notice_date <= today &&
      (!this.billing_info.stop_date ||
        today <= this.newDate(this.billing_info.stop_date))
    ) {
      return true;
    }

    // それ以外はdisableにしない
    return false;
  }

  /** 事業所電話番号(フル) */
  private get telNo() {
    const parts: string[] = [];
    parts.push(this.office.office_info.tel_area);
    parts.push(this.office.office_info.tel_city);
    parts.push(this.office.office_info.tel_num);

    return parts.join(" - ");
  }
}
