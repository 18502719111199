import Base from "./../base";
import { EwellUser } from "@/model/ewellUser";

export interface PortalMailLog extends Base {
  id: number | null;
  template_id: string | null;
  mail_subject: string | null;
  mail_body: string | null;
  mail_address: string | null;
  ewell_user_id?: string | null;
  portal_office_id?: number | null;
  portal_staff_id?: number | null;
  organization_id?: number | null;
  request_user_id?: number | null;
}

export interface PortalStaff extends Base {
  id: number | null;
  ewell_user_id: number | null;
  ibow_staff_id: number | null;
  portal_office_id: number | null;
  portal_office: PortalOffice | null;
  job_type: number | null;
  display_family_name: string | null;
  display_first_name: string | null;
  is_public_listing: boolean | null;
  icon: string | null;
  icon_url: string | null;
  role: number | null;
  system_id: string | null;
  ewell_user?: EwellUser | null;
}

export const DEFAULT_PORTAL_STAFF: PortalStaff = {
  id: 0,
  ewell_user_id: 0,
  ibow_staff_id: 0,
  portal_office_id: 0,
  portal_office: null,
  job_type: 0,
  display_family_name: "",
  display_first_name: "",
  is_public_listing: false,
  icon: "",
  icon_url: "",
  role: 0,
  system_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

export interface PortalOffice extends Base {
  id: number | null;
  portal_agreement_id: number | null;
  portal_agreement: PortalAgreement | null;
  ibow_office_id: number | null;
  approval_status: number | null;
  name: string | null;
  post_code: string | null;
  pref: number | null;
  city: string | null;
  area: string | null;
  building: string | null;
  tel_area: string | null;
  tel_city: string | null;
  tel_num: string | null;
  medical_institution_code: string | null;
  office_code: string | null;
  is_public: boolean | null;
  listing_review_status: number | null;
  business_start_date: string | null;
  listing_updated_at: string | null;
  listing_released_at: string | null;
}

export const DEFAULT_PORTAL_OFFICE: PortalOffice = {
  id: 0,
  portal_agreement_id: 0,
  portal_agreement: null,
  ibow_office_id: 0,
  approval_status: 0,
  name: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  medical_institution_code: "",
  office_code: "",
  is_public: false,
  listing_review_status: 0,
  business_start_date: "",
  listing_updated_at: "",
  listing_released_at: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

export interface PortalAgreement {
  id: number | null;
  ibow_agreement_id: number | null;
  co_name: string | null;
  post_code: string | null;
  pref: number | null;
  city: string | null;
  area: string | null;
  building: string | null;
  tel_area: string | null;
  tel_city: string | null;
  tel_num: string | null;
  fax_area: string | null;
  fax_city: string | null;
  fax_num: string | null;
  remarks: string | null;
  corporateNumber: string | null;
}

export const DEFAULT_PORTAL_AGREEMENT: PortalAgreement = {
  id: 0,
  ibow_agreement_id: 0,
  co_name: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  fax_area: "",
  fax_city: "",
  fax_num: "",
  remarks: "",
  corporateNumber: "",
};

export interface PortalOrganization {
  id: number | null;
  ibow_office_id: number | null;
  approval_status: number | null;
  organization_type: number | null;
  medical_institution_code: string | null;
  office_code: string | null;
  crypto_key: string | null;
  name: string | null;
  post_code: string | null;
  pref: number | null;
  city: string | null;
  area: string | null;
  building: string | null;
  tel_area: string | null;
  tel_city: string | null;
  tel_num: string | null;
  contact_tel_area: string | null;
  contact_tel_city: string | null;
  contact_tel_num: string | null;
  business_start_date: string | null;
  review_reason: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export const DEFAULT_PORTAL_ORGANIZATION: PortalOrganization = {
  id: 0,
  ibow_office_id: 0,
  approval_status: 0,
  organization_type: 0,
  medical_institution_code: "",
  office_code: "",
  crypto_key: "",
  name: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  contact_tel_area: "",
  contact_tel_city: "",
  contact_tel_num: "",
  business_start_date: "",
  review_reason: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

export interface RequestUser {
  id: number | null;
  ewell_user_id: number | null;
  organization_id: number | null;
  ibow_staff_id: number | null;
  display_first_name: string | null;
  display_last_name: string | null;
  icon: string | null;
  icon_url: string | null;
  role: number | null;
  office_mobile_area: string | null;
  office_mobile_city: string | null;
  office_mobile_num: string | null;
  is_office_mobile_public: boolean | null;
  system_id: string | null;
  job_type: number | null;
  ewell_user: EwellUser | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export const DEFAULT_REQUEST_USER: RequestUser = {
  id: 0,
  ewell_user_id: 0,
  organization_id: 0,
  ibow_staff_id: 0,
  display_first_name: "",
  display_last_name: "",
  icon: "",
  icon_url: "",
  role: 0,
  office_mobile_area: "",
  office_mobile_city: "",
  office_mobile_num: "",
  is_office_mobile_public: false,
  system_id: "",
  job_type: 0,
  ewell_user: null,
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

export interface PortalOfficialAnnouncement {
  id: number | null;
  destination: number | null;
  title: string | null;
  body: string | null;
  announced_at: string | null;
  status: number | null;
  is_mail_on: boolean | null;
  has_target: boolean | null;
  target_organizations: PortalOfficialAnnouncementTargetOrganization[] | null;
  target_portal_offices: PortalOfficialAnnouncementTargetOffice[] | null;
  display_start_at: string | null;
  display_end_at: string | null;
}

export interface PortalOfficialAnnouncementTargetOrganization {
  id: number | null;
  official_announcement_id: number | null;
  organization_id: number | null;
}

export interface PortalOfficialAnnouncementTargetOffice {
  id: number | null;
  official_announcement_id: number | null;
  portal_office_id: number | null;
}

export const DEFAULT_OFFICIAL_ANNOUNCEMENT: PortalOfficialAnnouncement = {
  id: 0,
  destination: 0,
  title: "",
  body: "",
  announced_at: "",
  status: 0,
  is_mail_on: false,
  has_target: false,
  target_organizations: [],
  target_portal_offices: [],
  display_start_at: "",
  display_end_at: "",
};
