





































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import AxiosMixin from '@/mixins/axiosMixin'
import UtilMixin from '@/mixins/utilMixin'
import AppAuthButton from '@/components/admin_common/AppAuthButton.vue'
import { PREFECTURES } from '@/consts/const'
import { PortalAgreement, DEFAULT_PORTAL_AGREEMENT } from '@/model/portal'

@Component({
  components: {
    AppAuthButton
  }
})
export default class PortalAgreementEdit extends Mixins(AxiosMixin, UtilMixin) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private portalAgreement: PortalAgreement = { ...DEFAULT_PORTAL_AGREEMENT };
  private showSaveCompleteBar = false;

  private PREFECTURES = PREFECTURES  
  
  public async created() {
    await this.fetchItem(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** 法人情報取得 */
  private async fetchItem(id: number) {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_office/get",
        { portal_office_id: id },
        (res) => {
          this.portalAgreement = res.data.portal_office.portal_agreement;
          resolve(0);
        }
      );
    });
  }

  /** 保存ボタンクリック時 */
  private async save(portalAgreement: PortalAgreement) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_agreement/update",
      { portal_agreement: portalAgreement },
      (res) => {
        this.portalAgreement = res.data.portal_agreement;
        this.showSaveCompleteBar = true;

        setTimeout(() => {
          this.$router.push({
            path: `/portal/portal-office-no-ibow/detail/${(Number(this.$route.params.id))}`,
          });
        }, 2000)
      }
    );
  }

  // キャンセルボタンクリック
  private async cancel() {
    const id = Number(this.$route.params.id);
    if (
      await this.$openConfirm(
        "編集を終了します\n編集中の場合は内容が破棄されます"
      )
    ) {
      this.$router.push({
        path: `/portal/portal-office-no-ibow/detail/${id}`,
      });
    }
  }
}
